import React from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import { followCursor } from 'tippy.js';

import 'tippy.js/dist/tippy.css';
// import 'tippy.js/themes/material.css';
// import 'tippy.js/animations/scale-subtle.css';

const Tooltip = ({
  content,
  children,
  type = 'default',
  placement = 'bottom',
  ...delegated
}) => {
  const Component = type === 'default' ? AnimatedTippy : StaticTippy;

  return (
    <Component
      followCursor={false}
      content={content}
      hideOnClick={false}
      placement={placement}
      {...delegated}
      plugins={type === 'default' ? [followCursor] : null}
    >
      {children}
    </Component>
  );
};

const StyledTippy = styled(Tippy)`
  --background: white;
  --text: hsl(274deg 16% 8%);

  padding: 8px 8px 12px 8px;
  font-size: 15px !important;
  text-align: center;
  /* prettier-ignore */
  filter:
    drop-shadow(0px 2px 4px hsl(274deg 16% 8% / 0.7))
    drop-shadow(0px 4px 8px hsl(274deg 16% 8% / 0.7));

  &.tippy-box {
    color: var(--text);
    background-color: var(--background);
  }
  & > .tippy-arrow {
    color: var(--background);
  }
`;

const AnimatedTippy = styled(StyledTippy)``;

const StaticTippy = styled(StyledTippy)`
  transform: translateY(-10px);
`;

export default Tooltip;
