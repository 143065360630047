import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { ArrowRight } from "react-feather";

import usePrefersReducedMotion from "@hooks/use-prefers-reduced-motion.hook";

const ListContext = React.createContext();

const DefaultIcon = () => (
  <ArrowRight size={20} style={{ color: "var(--color-primary)" }} />
);
const ICONS = {
  default: DefaultIcon,
};

const ListItem = ({ bullet = "default", animated, children }) => {
  const { type } = React.useContext(ListContext);

  const prefersReducedMotion = usePrefersReducedMotion();

  const IconComponent = ICONS[bullet];
  const [isHovering, setIsHovering] = React.useState(false);

  const iconSpring = useSpring(
    animated && !prefersReducedMotion
      ? {
          transform: isHovering ? `translateX(8px)` : `translateX(0px)`,
        }
      : {}
  );

  if (type === "original") {
    return <li>{children}</li>;
  }

  if (type === "ordered") {
    return (
      <Wrapper>
        <div>{children}</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <IconWrapper style={iconSpring}>
        <IconComponent />
      </IconWrapper>
      {/*
        This interaction is purely decorative, and is not required for
        folks using the keyboard or a screen-reader.
      */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="listitem"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {children}
      </div>
    </Wrapper>
  );
};

const List = ({ type = "unordered", ...delegated }) => {
  return (
    <ListContext.Provider value={{ type }}>
      {type === "ordered" && <OrderedListElem {...delegated} />}
      {type === "unordered" && <UnorderedListElem {...delegated} />}
      {type === "original" && <OriginalOrderedList {...delegated} />}
    </ListContext.Provider>
  );
};

const OriginalOrderedList = styled.ol`
  padding-left: 22px;
  margin-bottom: 32px;
  font-size: 1rem;

  li {
    margin-bottom: 16px;
  }
`;

const UnorderedListElem = styled.ul`
  font-size: 1rem;
  margin-bottom: 32px;
  line-height: 1.45;
  list-style: none;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    margin-bottom: 1.5rem;
  }

  & svg {
    display: block;
  }
`;

const OrderedListElem = styled.ol`
  font-size: 1rem;
  margin-bottom: 32px;
  counter-reset: counts;
  list-style: none;

  & li {
    counter-increment: counts;
    align-items: baseline;
  }

  & li::before {
    content: counters(counts, ".") ". ";
    font-feature-settings: "tnum";
    color: var(--color-primary);
    font-weight: var(--font-weight-medium);
    padding-right: 12px;
  }

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    font-size: 18px;
    margin-bottom: 1.5rem;
  }
`;

const Wrapper = styled.li`
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled(animated.span)`
  display: inline-block;
  padding-right: 16px;
  padding-top: 0;
  transform: translateY(5px);
`;

List.ListItem = ListItem;

export default List;
